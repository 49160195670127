import axios from 'axios';
import authHeader from './auth.service';
import { baseURL } from './constant';
const userInfo: any = localStorage.getItem('authToken');
const user: any = userInfo && userInfo

export default axios.create({
  baseURL: baseURL,
  headers:
    user 
      ? {
        "Authorization": `${"Bearer " + user}`,
        }
      : { 'Content-type': 'application/json' },
});
