import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const SubCategoryService = {
    addEditSubCategory,
    getSubCategoryById,
    getAllSubCategory,
    fetchAllSubCategories,
    deleteSubCategoryById,
    uploadSubCategoryImage,
    deleteSubCategoryImage,
};
function addEditSubCategory(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateSubCategoryById + id : apiUrl.createSubCategory, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getSubCategoryById(id:any) {
    return httpCommon.get(apiUrl.getSubCategoryById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllSubCategory() {
    return httpCommon.get(apiUrl.getAllSubCategory, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllSubCategories(data: any) {
    return httpCommon.post(apiUrl.fetchAllSubCategories, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteSubCategoryById(id:any) {
    return httpCommon.post(apiUrl.deleteSubCategoryById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function uploadSubCategoryImage(data:any,id:any) {
    return httpCommon.post(apiUrl.uploadSubCategoryImageDataById + id, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteSubCategoryImage(data:any, id:any) {
    return httpCommon.post(apiUrl.deleteSubCategoryImageDataById + id, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}