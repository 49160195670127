import { CONSTANT } from "../core/static-constant";

export const CommonService = {
    isValidPercentage,
    isUndefinedOrNull,
    isExactUndefinedOrNull,
    isArrEmpty,
    isObjEmpty,
    validURL,
    checkIsInvalidForm,
};

/*
To check percentage is valid or not.
*/
function isValidPercentage(number:any) {
    if (number > -1 && number <= 100) {
      return true
    } else {
      return false;
    }
}

/*
Check Undefined and null value
*/
function isUndefinedOrNull(value:any) {
    if (typeof value == CONSTANT.UNDEFINED || value == null || value == "") {
      return true;
    } else {
      return false;
    }
}
/*
Check Undefined and null value
*/
function isExactUndefinedOrNull(value:any) {
    if (typeof value == CONSTANT.UNDEFINED || value == null) {
      return true;
    } else {
      return false;
    }
}

/*
To array is undefined or null or empty.
*/
function isArrEmpty(arr:any) {
    return (arr == null || typeof arr === "undefined" || arr.length <= 0)
}
  
/*
To value is undefined or null.
*/
function isObjEmpty(obj:any) {
    return obj == null || typeof obj == "undefined" || Object.keys(obj).length == 0;
}

/*
To Check URL is valid or not.
*/
function validURL(urlString:any) {  
	let re = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
	return re.test(urlString.toLowerCase());
}

/*
To Check Form valid or not.
*/
async function checkIsInvalidForm(requiredField:any, obj:any) {
  if(isObjEmpty(obj)) {
		return true;
	} else {
    let value = false
    requiredField.forEach((field:any) => {
      if(isUndefinedOrNull(obj[field]) && value == false ){
        value = true;
      }
    });
    return value
  }
}