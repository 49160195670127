import React, { useEffect, useState } from 'react';
import Avatar from '../../../components/Avatar';

const CommonHeaderChat = () => {
	const [state, setState] = useState<boolean>(false);

	let data: any = localStorage.getItem("userData")
	data = JSON.parse(data)

	return (
		<>
			<div
				className='col d-flex align-items-center'
				onClick={() => setState(!state)}
				role='presentation'>
				<div className='me-3'>
					<div className='text-end'>
						<div className='fw-bold fs-6 mb-0'>				
							{data && data.data && data.data.userFirstName}
						</div>
						<div className='text-muted'>
							<small>{data && data.data && data.data.userEmailId}</small>
						</div>
					</div>
				</div>
				<div className='position-relative'>
					{/* <Avatar
						srcSet={USERS.CHLOE.srcSet}
						src={USERS.CHLOE.src}
						size={48}
						color={USERS.CHLOE.color}
					/>					 */}
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span>
				</div>
			</div>		
		</>
	);
};

export default CommonHeaderChat;
