import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const EventService = {
    addEditEvent,
    getEventById,
    getAllEvent,
    deleteEventById,
};
function addEditEvent(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateEventById + id : apiUrl.createEvent, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getEventById(id:any) {
    return httpCommon.get(apiUrl.getEventById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllEvent() {
    return httpCommon.get(apiUrl.getAllEvent, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteEventById(id:any) {
    return httpCommon.post(apiUrl.deleteEventById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}