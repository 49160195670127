import React, { useState, useEffect } from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Select from '../../../components/bootstrap/forms/Select';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import { routingConstant } from '../../../core/constant';
import { BrandService, CategoryService, SubCategoryService, ProductCodeService, CommonService } from '../../../services/index';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { CONSTANT } from '../../../core/static-constant';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddEditProduct = () => {
	const navigate = useNavigate();
	const params = useParams();
	const [productCodeObj, setProductCodeObj] = useState<any>({
		productName: "",
		productSubcategory: "",
		brand: "",
		category: "",
		productType: "",
		shortDescripton: "",
		longDescription: "",
		productTextField1: "",
		productTextField2: "",
		productTextField3: "",
		productTextField4: "",
		productTextField5: "",
		productTextField6: "",
		productTextField7: "",
		productTextField8: "",
		LinktoOBJFile: "",
		productMRP: 0,
		productDiscount: "",
		productSellPrice: 0,
		productGender: "",
		productSize: "",
		productRedeemPoint: "",
		productPriority: 0,
		status: "",
		createdBy: "",
	})
	const [productCodeId, setProductCodeId] = useState("")
	const [image1, setImage1] = useState<any[]>([])
	const [brandList, setBrandList] = useState([])
	const [categoryList, setCategoryList] = useState([])
	const [allSubCategoryList, setAllSubCategoryList] = useState<any[]>([])
	const [subCategoryList, setSubCategoryList] = useState<any[]>([])
	const [isInitialized, setIsInitialized] = useState(false)
	const [productCodeData, setProductCodeData] = useState({})
	const [isInvalidForm, setIsInvalidForm] = useState(false)

	useEffect(() => {
		if (!isInitialized) {
			setIsInitialized(true)
		} else {
			const init = async () => {
				const userData: any = await JSON.parse(localStorage.getItem("userData") || "")
				await setProductCodeObj({ ...productCodeObj, createdBy: userData.id })
				await getAllBrand()
				await getAllCategory()
				await getAllSubCategory()
				if (params && params.id) {
					await setProductCodeId(params.id)
					await getProductCodeById(params.id)
				}
			}
			init()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps 
	}, [isInitialized])

	const resetProductObj = (data: any) => {
		setProductCodeObj({
			productName: data?.productName || "",
			productSubcategory: data?.productSubcategory?._id || "",
			brand: data?.brand?._id || "",
			category: data?.category?._id || "",
			productType: data?.productType || "",
			shortDescripton: data?.shortDescripton || "",
			longDescription: data?.longDescription || "",
			productTextField1: data?.productTextField1 || "",
			productTextField2: data?.productTextField2 || "",
			productTextField3: data?.productTextField3 || "",
			productTextField4: data?.productTextField4 || "",
			productTextField5: data?.productTextField5 || "",
			productTextField6: data?.productTextField6 || "",
			productTextField7: data?.productTextField7 || "",
			productTextField8: data?.productTextField8 || "",
			LinktoOBJFile: data?.LinktoOBJFile || "",
			productMRP: data?.productMRP || "",
			productDiscount: data?.productDiscount || "",
			productSellPrice: data?.productSellPrice || "",
			productGender: data?.productGender || "",
			productSize: data?.productSize || "",
			productRedeemPoint: data?.productRedeemPoint || "",
			status: data?.status || "",
			productPriority: data?.productPriority || "",
		})
	}

	const getProductCodeById = async (id: any) => {
		let data: any = await ProductCodeService.getProductCodeById(id)
		if (data && data.status === CONSTANT.SUCCESS) {
			const responseData = data.data
			setProductCodeData(responseData);
			selectCategory(responseData.category? responseData.category._id : "");
			resetProductObj(responseData)
		} else {
			toast.error(data.message)
		}
	}

	const onSaveClickHandler = async () => {
		if (await CommonService.checkIsInvalidForm(CONSTANT.PRODUCT_CODE_REQUIRED_FIELD, { ...productCodeObj })) {
			setIsInvalidForm(true)
		}  else if (productCodeObj.productTextField2 && !CommonService.validURL(productCodeObj.productTextField2)) {
			setIsInvalidForm(true)			
		  } else {
			setIsInvalidForm(false)
			let data = await ProductCodeService.addEditProductCodes({ ...productCodeObj }, productCodeId)
			if (data && data.status === CONSTANT.SUCCESS) {
				onCancelClick()
			} else {
				setTimeout(() => {
					toast.error(data.message)
				}, 1000);
			}
		}
	}

	const getAllBrand = async () => {
		let data: any = await BrandService.getAllBrands()
		if (data && data.status === CONSTANT.SUCCESS) {
			const responseData = data.data
			setBrandList(responseData);
		} else {
			toast.success(data.message);
		}
	}

	const getAllSubCategory = async () => {
		let data: any = await SubCategoryService.getAllSubCategory()
		if (data && data.status === CONSTANT.SUCCESS) {
			const responseData = data.data
			setAllSubCategoryList(responseData);
		} else {
			toast.success(data.message);
		}
	}

	const getAllCategory = async () => {
		let data: any = await CategoryService.getAllCategories()
		if (data && data.status === CONSTANT.SUCCESS) {
			const responseData = data.data
			setCategoryList(responseData);
		} else {
			toast.success(data.message);
		}
	}

	const onCancelClick = async () => {
		await setProductCodeId("")
		await resetProductObj({})
		navigate(routingConstant.productCodeView)
	}

	const selectCategory = (categoryId: any) => {
         let selectedSubCategory: any = []
		 selectedSubCategory = allSubCategoryList.filter((subCatItem) => subCatItem.category && categoryId == subCatItem.category._id) 
		 setSubCategoryList([...selectedSubCategory]);
	}

	return (
		<PageWrapper>
			<div className='page container-xxl align-items-center'>
				<Card className='w-100'>
					<CardBody>
						<h3 className='text-center mt-3 mb-5 text-primary'>
							<strong>{productCodeId ? CONSTANT.UPDATE : CONSTANT.ADD} Product Code </strong>
						</h3>
						<div className='row g-4'>
							<div className='col-sm-6 col-md-4'>
								<FormGroup id='productName' label='Prodcut Name*' isFloating>
									<>
										<Input
											placeholder='Prodcut Name'
											className={`${isInvalidForm && CommonService.isUndefinedOrNull(productCodeObj.productName) ? 'border border-danger': ''}`} value={productCodeObj.productName}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productName: e.target.value })}
										/>
										{
											isInvalidForm && CommonService.isUndefinedOrNull(productCodeObj.productName) &&
											<p className="text-danger ms-2"><small>Product Name is required</small></p>
										}
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-4'>
								<FormGroup id='productType' label='Product Type' isFloating>
									<>										
										<Select ariaLabel='Board select' onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productType: e.target.value })} value={productCodeObj.productType} >
										<option  value={""} disabled> Select Product Type </option>
											<option key={"Bespoke"} value={"Bespoke"} > Bespoke </option>
											<option key={"Voucher"} value={"Voucher"} > Voucher </option>
											<option key={"Brand"} value={"Brand"} > Brand </option>
										</Select>
										{
											isInvalidForm && CommonService.isUndefinedOrNull(productCodeObj.productType) &&
											<p className="text-danger ms-2"><small>Product Type is required</small></p>
										}
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-4'>
								<FormGroup id='productGender' label='Product Gender' isFloating>
									<>
										<Select ariaLabel='Board select' onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productGender: e.target.value })} value={productCodeObj.productGender} >
										<option  value={""} disabled> Select Gender </option>
											<option key={"Male"} value={"Male"} > Male </option>
											<option key={"Female"} value={"Female"} > Female </option>
										</Select>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-4'>
								<FormGroup id='location' label='Category Name*' isFloating>
									<>
										<Select ariaLabel='Board select' value={productCodeObj.category}
											onChange={(e: any) => {selectCategory(e.target.value);setProductCodeObj({ ...productCodeObj, category: e.target.value });}} >
												<option  value={""} disabled> Select Category Name </option>
											{
												categoryList.map((element: any, i: any) => {
													return <option key={i} value={element._id} > {element.categoryName} </option>
												})
											}
										</Select>
										{
											isInvalidForm && CommonService.isUndefinedOrNull(productCodeObj.category) &&
											<p className="text-danger ms-2"><small>Category is required</small></p>
										}
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-4'>
								<FormGroup id='productSubcategory' label='Sub Category Name' isFloating>
									<>
										<Select ariaLabel='Board select' value={productCodeObj.productSubcategory}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productSubcategory: e.target.value })} >
												<option  value={""} disabled> Select Sub Category Name </option>
											{
												subCategoryList.map((element: any, i: any) => {
													return <option key={i} value={element._id} > {element.subCatgeoryName} </option>
												})
											}
											{
												isInvalidForm && CommonService.isUndefinedOrNull(productCodeObj.productSubcategory) &&
												<p className="text-danger ms-2"><small>Sub Category Name is required</small></p>
											}
										</Select>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-4'>
								<FormGroup id='brand' label='Brand*' isFloating>
									<>
										<Select ariaLabel='Board select' value={productCodeObj.brand}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, brand: e.target.value })} >
												<option  value={""} disabled> Select Brand Name </option>
											{
												brandList.map((element: any, i: any) => {
													return <option key={i} value={element._id} > {element.brandName} </option>
												})
											}
										</Select>
										{
											isInvalidForm && CommonService.isUndefinedOrNull(productCodeObj.brand) &&
											<p className="text-danger ms-2"><small>Brand is required</small></p>
										}
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='productMRP' label='Product Mrp' isFloating>
									<>
										<Input
											type={"number"}
											placeholder='Product Mrp'
											value={productCodeObj.productMRP}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productMRP: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='productDiscount' label='Product Discount' isFloating>
									<>
										<Input
											placeholder='Product Discount'
											value={productCodeObj.productDiscount}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productDiscount: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='productSellPrice' label='Product Sell Price' isFloating>
									<>
										<Input
											type={"number"}
											placeholder='Product Sell Price'
											value={productCodeObj.productSellPrice}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productSellPrice: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='productRedeemPoint' label='Product Redeem Point' isFloating>
									<>
										<Input
											placeholder='Product Redeem Point'
											value={productCodeObj.productRedeemPoint}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productRedeemPoint: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 1' isFloating>
									<>
										<Input
											placeholder='Product Text Feild 1'
											value={productCodeObj.productTextField1}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField1: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 2' isFloating>
									<>
										<Input
											placeholder='Product Text Feild 2'
											value={productCodeObj.productTextField2}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField2: e.target.value })}
										/>
										{
											isInvalidForm && !CommonService.validURL(productCodeObj.productTextField2) &&
											<p className="text-danger ms-2"><small>Please enter valid URL</small></p>
										}
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 3' isFloating>
									<>
										<Textarea
											rows={3}
											className="mh-90"
											placeholder='Product Text Feild 3'
											value={productCodeObj.productTextField3}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField3: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 4' isFloating>
									<>
										<Textarea
											rows={3}
											className="mh-90"
											placeholder='Product Text Feild 4'
											value={productCodeObj.productTextField4}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField4: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 5' isFloating>
									<>
										<Textarea
											rows={3}
											className="mh-90"
											placeholder='Product Text Feild 5'
											value={productCodeObj.productTextField5}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField5: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 6' isFloating>
									<>
										<Input
											placeholder='Product Text Feild 6'
											value={productCodeObj.productTextField6}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField6: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 7' isFloating>
									<>
										<Input
											placeholder='Product Text Feild 7'
											value={productCodeObj.productTextField7}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField7: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Product Text Feild 8' isFloating>
									<>
										<Input
											placeholder='Product Text Feild 8'
											value={productCodeObj.productTextField8}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productTextField8: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Short Descripton' isFloating>
									<>
										<Textarea
											rows={3}
											className="mh-90"
											placeholder='Short Descripton'
											value={productCodeObj.shortDescripton}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, shortDescripton: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-12 col-md-6'>
								<FormGroup id='location' label='Long Descripton' isFloating>
									<>
										<Textarea
											rows={3}
											className="mh-90"
											placeholder='Long Descripton'
											value={productCodeObj.longDescription}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, longDescription: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='location' label='Link to OBJFile' isFloating>
									<>
										<Input
											placeholder='Link to OBJFile'
											value={productCodeObj.LinktoOBJFile}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, LinktoOBJFile: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='location' label='Product Size' isFloating>
									<>
										<Select ariaLabel='Board select' onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productSize: e.target.value })} value={productCodeObj.productSize} >
										<option  value={""} disabled> Select Product Size </option>
											<option key={"small"} value={"Small"} > Small </option>
											<option key={"medium"} value={"Medium"} > Medium </option>
											<option key={"large"} value={"Large"} > Large </option>
										</Select>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='Status' label='Status' isFloating>
									<>
										<Select ariaLabel='Board select' onChange={(e: any) => setProductCodeObj({ ...productCodeObj, status: e.target.value })} value={productCodeObj.status} >
											<option  value={""} disabled> Select Status </option>
											{
												CONSTANT.STATUS_LIST.map((list:any) => <option key={list.id} value={list.id} > {list.value} </option>)
											}
										</Select>
									</>
								</FormGroup>
							</div>
							<div className='col-sm-6 col-md-3'>
								<FormGroup id='location' label='Product Priority' isFloating>
									<>
										<Input
											type={"number"}
											min={0}
											max={1}
											placeholder='Product Priority'
											value={productCodeObj.productPriority}
											onChange={(e: any) => setProductCodeObj({ ...productCodeObj, productPriority: e.target.value })}
										/>
									</>
								</FormGroup>
							</div>
						</div>
						<Button
							color='info'
							className='w-25 mt-5'
							onClick={(event: any) => { event.preventDefault(); onSaveClickHandler() }}
						>
							Save
						</Button>
						<Button
							type="button"
							color='danger'
							className='w-25 mt-5 float-end'
							onClick={(event: any) => { event.preventDefault(); onCancelClick() }}
						>
							Cancel
						</Button>
					</CardBody>
				</Card>
			</div>
		</PageWrapper>
	)
}

export default AddEditProduct;

