import React, { useState, useEffect, useRef } from 'react'
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import { routingConstant, baseURL } from '../../../core/constant';
import { SubCategoryService, CategoryService, CommonService } from '../../../services/index';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { CONSTANT } from '../../../core/static-constant';
import NoImage from '../../../assets/noImage.png';
import { toast } from 'react-toastify';

const AddEditSubCategory = () => {
    const imgPath = baseURL + CONSTANT.IMG_PATH.SUB_CATEGORY;
    const params = useParams();
    const navigate = useNavigate();

    const img1Ref = useRef<any>(null)
    const img2Ref = useRef<any>(null)
    const [isInitialized, setIsInitialized] = useState(false)
    const [subCatgeoryId, setSubCategoryId] = useState("");
    const [category, setCategory] = useState("");
    const [subCatgeoryName, setSubCategoryName] = useState("");
    const [subCategoryImage1Name, setSubCategoryImage1Name] = useState("")
    const [subCategoryImage2Name, setSubCategoryImage2Name] = useState("")
    const [subCategoryImage1, setSubCategoryImage1] = useState([])
    const [subCategoryImage2, setSubCategoryImage2] = useState([])
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState({});
    const [isInvalidForm, setIsInvalidForm] = useState(false)


    useEffect(() => {
        const init = async () => {
            if (!isInitialized) {
                setIsInitialized(true)
            } else {
                await getAllCategories()
                if (params && params.id) {
                    setSubCategoryId(params.id)
                    getSubCategoryById(params.id)
                }
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [isInitialized])

    const getSubCategoryById = async (id: any) => {
        let data: any = await SubCategoryService.getSubCategoryById(id)
        if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data.data
            setSubCategoryData(responseData);
            resetSubCategoryObj(responseData)
        } else {
            alert(data.message)
        }
    }

    const onSaveHandleClick = async () => {
        if (await CommonService.checkIsInvalidForm(CONSTANT.SUB_CATEGORY_REQUIRED_FIELD, { category, subCatgeoryName })) {
            setIsInvalidForm(true)
        } else if(CommonService.isUndefinedOrNull([subCategoryImage1Name]) && CommonService.isUndefinedOrNull([subCategoryImage2Name])) {
            setIsInvalidForm(true)
        } else {
            setIsInvalidForm(false)
            let formData = new FormData();
            let object: any = {}
            if (!subCatgeoryId) {
                formData = new FormData();
                formData.append("category", category)
                formData.append("subCatgeoryName", subCatgeoryName)
                formData.append("subCategoryImage1", subCategoryImage1[0])
                formData.append("subCategoryImage2", subCategoryImage2[0])
            } else {
                object.category = category
                object.subCatgeoryName = subCatgeoryName
            }
            try {
                let data: any = await SubCategoryService.addEditSubCategory(subCatgeoryId ? object : formData, subCatgeoryId)
                if (data && data.status === CONSTANT.SUCCESS) {
                    resetSubCategoryObj({})
                    navigate(routingConstant.subCategoryView)
                } else {
                    toast.error(data.message)
                }
            } catch (error) {
                toast.error(CONSTANT.ERROR_OCCURRED)
            }
        }
    }

    const resetSubCategoryObj = (data: any) => {
        setCategory(data?.category?._id || "")
        setSubCategoryName(data?.subCatgeoryName || "")
        setSubCategoryImage1Name(data?.subCategoryImage1?.filename || "")
        setSubCategoryImage2Name(data?.subCategoryImage2?.filename || "")
    }


    const onCancelClick = () => {
        setSubCategoryId("")
        resetSubCategoryObj({})
        navigate(routingConstant.subCategoryView)
    }

    const onUploadSubCategoryImage = async (event: any, field: any) => {
        let formData = new FormData();
        formData.append(field, event.target.files[0])
        try {
            let data: any = await SubCategoryService.uploadSubCategoryImage(formData, subCatgeoryId)
            if (data && data.status === CONSTANT.SUCCESS) {
                getSubCategoryById(subCatgeoryId)
            } else {
                alert(data.message)
            }
        } catch (error) {
            alert(CONSTANT.ERROR_OCCURRED)
        }
    }

    const onDeleteImageClick = async (fieldName: string) => {
        let obj: any = { fieldName: fieldName }
        if (fieldName == "subCategoryImage1") {
            obj.filename = subCategoryImage1Name
        } else {
            obj.filename = subCategoryImage2Name
        }
        try {
            let data: any = await SubCategoryService.deleteSubCategoryImage(obj, subCatgeoryId)
            if (data && data.status === CONSTANT.SUCCESS) {
                getSubCategoryById(subCatgeoryId)
            } else {
                alert(data.message)
            }
        } catch (error) {
            alert(CONSTANT.ERROR_OCCURRED)
        }
    }

    const getAllCategories = async () => {
        try {
            let data: any = await CategoryService.getAllCategories()
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data.data
                setCategoryData(responseData);
            } else {
                alert(data.message);
            }
        } catch (error) {
            alert(CONSTANT.ERROR_OCCURRED);
        }
    }

    return (
        <div className='page container-xxl align-items-center'>
            <Card className='w-100'>
                <CardBody>
                    <h3 className='text-center mt-3 mb-5 text-primary'>
                        <strong> {subCatgeoryId ? CONSTANT.UPDATE : CONSTANT.ADD} SubCategory</strong>
                    </h3>
                    <div className='row g-4'>
                    <div className='col-sm-12 col-md-6'>
                        <FormGroup>
                            <>
                                <select className={`form-select ${isInvalidForm && CommonService.isUndefinedOrNull(category) ? 'border border-danger': ''}`}  aria-label="Default select example" value={category} onChange={(e: any) => setCategory(e.target.value)}>
                                <option  value={""} disabled> Select Category Name* </option>
                                    {
                                        categoryData && categoryData.map((ele: any, i: any) => {
                                            return (
                                                <option key={i} value={ele._id}>{ele.categoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    isInvalidForm && CommonService.isUndefinedOrNull(category) &&
                                    <p className="text-danger ms-2"><small>Category is required</small></p>
                                }
                            </>
                        </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='service' label='Sub Category Name*' isFloating>
                                <>
                                    <Input
                                        placeholder='Sub Category Name'
                                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(subCatgeoryName) ? 'border border-danger': ''}`}
                                        value={subCatgeoryName}
                                        onChange={(e: any) => setSubCategoryName(e.target.value)}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(subCatgeoryName) &&
                                        <p className="text-danger ms-2"><small>Sub Category is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>

                        {
                            !subCatgeoryId &&
                            <>
                                <div className='col-6 mt-5'>
                                    <Input
                                        type={'file'}
                                        autoComplete='photo'
                                        onChange={(e: any) => { setSubCategoryImage1(e.target.files);setSubCategoryImage1Name(e.target.files[0].name) }}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(subCategoryImage1Name) &&
                                        <p className="text-danger ms-2"><small>Image is required</small></p>
                                      }
                                </div>
                                <div className='col-6 mt-5'>
                                    <Input
                                        type={'file'}
                                        autoComplete='photo'
                                        onChange={(e: any) => { setSubCategoryImage2(e.target.files);setSubCategoryImage2Name(e.target.files[0].name) }}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(subCategoryImage2Name) &&
                                        <p className="text-danger ms-2"><small>Image is required</small></p>
                                      }
                                </div>
                            </>
                        }
                        <div>
                            <Button
                                type="button"
                                color='info'
                                className='w-25 mt-5'
                                onClick={() => { onSaveHandleClick(); }}
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                color='danger'
                                className='w-25 mt-5 float-end'
                                onClick={(event: any) => { event.preventDefault(); onCancelClick() }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    {
                        subCatgeoryId &&
                        <div className='row g-4'>
                            <div className='col-6 mt-5'>
                                <div className='d-inline-flex'>
                                    <img className='cursor-pointer border border-light' src={subCategoryImage1Name && imgPath + subCategoryImage1Name || NoImage} alt="img1" height={150} width={150} onClick={() => img1Ref.current.click()} />
                                    {
                                        subCategoryImage1Name &&
                                        <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"} onClick={() => onDeleteImageClick("subCategoryImage1")} />
                                    }
                                </div>
                                <Input
                                    hidden={!!subCatgeoryId}
                                    ref={img1Ref}
                                    type={'file'}
                                    autoComplete='photo'
                                    onChange={(e: any) => { setSubCategoryImage1(e.target.files); onUploadSubCategoryImage(e, "subCategoryImage1") }}
                                />
                            </div>
                            <div className='col-6 mt-5'>
                                <div className='d-inline-flex'>
                                    <img className='cursor-pointer border border-light' src={subCategoryImage2Name && imgPath + subCategoryImage2Name || NoImage} alt="img2" height={150} width={150} onClick={() => img2Ref.current.click()} />
                                    {
                                        subCategoryImage2Name &&
                                        <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"} onClick={() => onDeleteImageClick("subCategoryImage2")} />
                                    }
                                </div>
                                <Input
                                    hidden={!!subCatgeoryId}
                                    type={'file'}
                                    ref={img2Ref}
                                    autoComplete='photo'
                                    onChange={(e: any) => { setSubCategoryImage2(e.target.files); onUploadSubCategoryImage(e, "subCategoryImage2") }}
                                />
                            </div>
                        </div>
                    }
                </CardBody>
            </Card>
        </div >
    )
}

export default AddEditSubCategory;