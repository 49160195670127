import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const ProductCodeService = {
    addEditProductCodes,
    getProductCodeById,
    getAllProductCodes,
    fetchAllProductCode,
    deleteProductCodeById,
    uploadProductCodeImage,
    deleteProductCodeImage
};


function addEditProductCodes(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateProductCode + id : apiUrl.createProductCode, {...data}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getProductCodeById(id:any) {
    return httpCommon.get(apiUrl.getProductCodeById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllProductCodes() {
    return httpCommon.get(apiUrl.getAllProductCode, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllProductCode(data: any) {
    return httpCommon.post(apiUrl.fetchAllProductCode, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteProductCodeById(id:any) {
    let url = apiUrl.deleteProductCodeById + id
    return httpCommon.post(url).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function uploadProductCodeImage(data:any,id:any) {
    let url = apiUrl.uploadProductCodeImageById + id
    return httpCommon.post(url,data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteProductCodeImage(id:any) {
    let url = apiUrl.deleteProductCodeImageById + id
    return httpCommon.post(url).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}