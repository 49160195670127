export const CONSTANT = Object.freeze({
    SUCCESS: "success",
    FAIL: "fail",
    ERROR: "error",
    UNDEFINED: "undefined",
    ADD: "Add",
    UPDATE: "Update",
    ACTIVE: "Active",
    DEACTIVE: "Deactive",
    DESC: "desc",
    ASC: "asc",
    ERROR_OCCURRED: "Something went error",
    DATE_FORMATE: "DD-MMM-YYYY",
    DATE_FORMATE1: "YYYY-MM-DD",
    IMG_PATH : {
        SUB_CATEGORY : "uploaded_attachment/category/subCategory_images/",
        CATEGORY : "uploaded_attachment/category/category_images/",
        BRAND : "uploaded_attachment/brand/brand_images/",
    },
    STATUS_LIST : [
        { id: "Active", value: "Active" },
        { id: "Deactive", value: "Deactive" },
    ],
    AVATAR_REQUIRED_FIELD:["avatarName", "avatarShortDescripton", "avatarLongDescription", "avatarThumbImage", "avatarGender", "avatarCoverImage", "avatar3DLink","avatarPersonaName"],
    BRAND_REQUIRED_FIELD:["brandName", "brandDescription", "brandLogoUrl", "pointDiscountLimit", "brandType", "categoryId", "subCategoryId"],
    CATEGORY_REQUIRED_FIELD:["categoryName", "categoryGender"],
    CUSTOMIZATION_ITEM_REQUIRED_FIELD:["product", "customizationPoint", "itemName", "thumbnail"],
    CUSTOMIZATION_POINT_REQUIRED_FIELD:["customizationName", "shortDescription"],
    MAPPING_REQUIRED_FIELD:["avatarCode", "productCode", "cp3DLink"],
    PRODUCT_CODE_REQUIRED_FIELD:["productName", "brand", "category", "productType", "productSubcategory"],
    SUB_CATEGORY_REQUIRED_FIELD:["category", "subCatgeoryName"],
    PINCODE_REQUIRED_FIELD:["pincode", "country", "status"],
    EVENT_REQUIRED_FIELD:["eventName", "eventPointEarn"],
    PRODUCT_CODE_INVENTORY_REQUIRED_FIELD:["product", "size", "qty"],
    PROMO_CODE_REQUIRED_FIELD:["code", "startDate", "endDate"],
})