import { routingConstant } from '../../../core/constant';
import React, { useState, useEffect } from 'react'
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { CONSTANT } from '../../../core/static-constant';
import Select from '../../../components/bootstrap/forms/Select';
import { AvatarService, CommonService } from '../../../services';
import { toast } from 'react-toastify';
import Textarea from '../../../components/bootstrap/forms/Textarea';

const AddEditAvatar = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [isInitialized, setIsInitialized] = useState(false)
    const [isInvalidForm, setIsInvalidForm] = useState(false)
    const [avatarObj, setAvatarObj] = useState({
        avatarName: "",
        avatarPersonaName: "",
        avatarGender: "",
        avatarShortDescripton: "",
        avatarLongDescription: "",
        avatarThumbImage: "",
        avatarCoverImage: "",
        avatar3DLink: ""
    });
    const [avatarId, setAvatarId] = useState("");
    const [avatarData, setAvatarData] = useState({});

    useEffect(() => {
		if (!isInitialized) {
		  setIsInitialized(true)
		} else {
			const init = async () => {			
				if(params && params.id) {
					setAvatarId(params.id)
					getAvatarById(params.id)
				}
			}
			init()
		}        
        // eslint-disable-next-line react-hooks/exhaustive-deps  
	  }, [isInitialized])

    
	const getAvatarById = async (id: any) => {
        try {
            let data:any = await AvatarService.getAvatarById(id)
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data.data
                setAvatarData(responseData);
                resetAvatarObj(responseData);
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            toast.error(CONSTANT.ERROR_OCCURRED)
        }
	}

    const onSaveClickHandler = async() => {
        if(await CommonService.checkIsInvalidForm(CONSTANT.AVATAR_REQUIRED_FIELD, { ...avatarObj })) {
            setIsInvalidForm(true)
        } else if(!CommonService.validURL(avatarObj.avatarThumbImage) || !CommonService.validURL(avatarObj.avatarCoverImage) || !CommonService.validURL(avatarObj.avatar3DLink)){
            setIsInvalidForm(true)
        } else {
            setIsInvalidForm(false)
            try {
                let data = await AvatarService.addEditAvatar({...avatarObj},avatarId)
                if (data && data.status === CONSTANT.SUCCESS) {
                    onCancelClick()
                } else {
                    toast.error(data.message)
                }
            } catch (error) {
                toast.error(CONSTANT.ERROR_OCCURRED)
            }
        }
	}

    const onCancelClick = () => {
        setAvatarId("")
        resetAvatarObj({})
        navigate(routingConstant.avatarView)
    }

    const resetAvatarObj = (data:any) => {
        setAvatarObj({
            avatarName: data.avatarName || "",
            avatarPersonaName: data.avatarPersonaName || "",
            avatarGender: data.avatarGender || "",
            avatarShortDescripton: data.avatarShortDescripton || "",
            avatarLongDescription: data.avatarLongDescription || "",
            avatarThumbImage: data.avatarThumbImage || "",
            avatarCoverImage: data.avatarCoverImage || "",
            avatar3DLink: data.avatar3DLink || "",
        })
    }

    return (
        <div className='page container-xxl align-items-center'>
            <Card>
                <CardBody>
                <h3 className='text-center mt-3 mb-5 text-primary'>
                        <strong> {avatarId ? CONSTANT.UPDATE : CONSTANT.ADD} Avtar</strong>
                    </h3>
                    <div className='row g-4'>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='customerName' label='Avatar Name*' isFloating>
                                <>
                                    <Input
                                        placeholder='Avatar Name'
                                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarName) ? 'border border-danger': ''}`}
                                        value={avatarObj.avatarName}
                                        onChange={(e: any) => setAvatarObj({...avatarObj,avatarName:e.target.value})}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarName) &&
                                        <p className="text-danger ms-2"><small>Name is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='service' label='Avatar Persona Name*' isFloating>
                                <>
                                    <Input
                                        placeholder='Avatar Persona Name'
                                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarPersonaName) ? 'border border-danger': ''}`}
                                        value={avatarObj.avatarPersonaName}
                                        onChange={(e: any) => setAvatarObj({...avatarObj,avatarPersonaName:e.target.value})}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarPersonaName) &&
                                        <p className="text-danger ms-2"><small>Person Name is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='avatarGender' label='Avatar Gender*' isFloating>
                                <>
                                    <Select ariaLabel='Board select' className={`${isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarGender) ? 'border border-danger': ''}`} onChange={(e: any) => setAvatarObj({ ...avatarObj, avatarGender: e.target.value })} value={avatarObj.avatarGender} >
                                    <option  value={""} disabled> Select Gender </option>
                                        <option key={"Male"} value={"Male"} > Male </option>
                                        <option key={"Female"} value={"Female"} > Female </option>
                                    </Select>
                                </>
                                </FormGroup>
                                  <>
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarGender) &&
                                        <p className="text-danger ms-2"><small>Gender is required</small></p>
                                    }
                                </>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                        <FormGroup id='location' label='Avatar Thumb Image*' isFloating>
                        <>
                        <Input
                        placeholder='Avatar Thumb Image'
                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarThumbImage) ? 'border border-danger': ''}`}
                        value={avatarObj.avatarThumbImage}
                        onChange={(e: any) => setAvatarObj({...avatarObj,avatarThumbImage:e.target.value})}
                        />
                        {
                            isInvalidForm &&
                            <>
                            {
                                CommonService.isUndefinedOrNull(avatarObj.avatarThumbImage) &&
                                <p className="text-danger ms-2"><small>Thumb Image is required</small></p>
                            }
                            {
                                !CommonService.isUndefinedOrNull(avatarObj.avatarThumbImage) && !CommonService.validURL(avatarObj.avatarThumbImage) &&
                                <p className="text-danger ms-2"><small>Please enter valid URL</small></p>
                            }
                            </>
                        }
                        </>
                        </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                        <FormGroup id='location' label='Avatar Cover Image*' isFloating>
                        <>
                        <Input
                        placeholder='Avatar Cover Image'
                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarCoverImage) ? 'border border-danger': ''}`}
                        value={avatarObj.avatarCoverImage}
                        onChange={(e: any) => setAvatarObj({...avatarObj,avatarCoverImage:e.target.value})}
                        />
                        {
                            isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarCoverImage) &&
                            <p className="text-danger ms-2"><small>Cover Image is required</small></p>
                        }
                        {
                            !CommonService.isUndefinedOrNull(avatarObj.avatarCoverImage) && !CommonService.validURL(avatarObj.avatarCoverImage) &&
                            <p className="text-danger ms-2"><small>Please enter valid URL</small></p>
                        }
                        </>
                        </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                        <FormGroup id='location' label='Avatar 3D Link*' isFloating>
                        <>
                        <Input
                        placeholder='Avatar 3D Link'
                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatar3DLink) ? 'border border-danger': ''}`}
                        value={avatarObj.avatar3DLink}
                        onChange={(e: any) => setAvatarObj({...avatarObj,avatar3DLink:e.target.value})}
                        />
                        {
                            isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatar3DLink) &&
                            <p className="text-danger ms-2"><small>3D Link is required</small></p>
                        }
                        {
                            !CommonService.isUndefinedOrNull(avatarObj.avatar3DLink) && !CommonService.validURL(avatarObj.avatar3DLink) &&
                            <p className="text-danger ms-2"><small>Please enter valid URL</small></p>
                        }
                        </>
                        </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='location' label='Avatar Short Description*' isFloating>
                            <>                               
                                <Textarea
                                rows={3}
                                className="mh-90"
                                placeholder='Avatar Short Description'
                                value={avatarObj.avatarShortDescripton}
                                onChange={(e: any) => setAvatarObj({...avatarObj,avatarShortDescripton:e.target.value})}
                            />
                                {
                                    isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarShortDescripton) &&
                                    <p className="text-danger ms-2"><small>Short Description is required</small></p>
                                }
                            </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='location' label='Avatar Long Description*' isFloating>
                                <>                                   
                                    <Textarea
                                    rows={3}
                                    className="mh-90"
                                    placeholder='Avatar Long Description'
                                    value={avatarObj.avatarLongDescription}
                                    onChange={(e: any) => setAvatarObj({...avatarObj,avatarLongDescription:e.target.value})}
                                />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(avatarObj.avatarLongDescription) &&
                                        <p className="text-danger ms-2"><small>Long Description is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <Button
                            type="button"
                            color='info'
                            className='w-25 mt-5'
                            onClick={() => onSaveClickHandler()}
                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            color='danger'
                            className='w-25 mt-5 float-end'
                            onClick={(event:any) => { event.preventDefault(); onCancelClick()}}
                        >
                            Cancel
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div >
    )
}

export default AddEditAvatar;