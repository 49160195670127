import React from 'react';
import { RouteProps } from 'react-router-dom';
import {	
	dashboardPagesMenu,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';

const headers: RouteProps[] = [
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.product.path, element: <DashboardHeader /> },		
	{ path: dashboardPagesMenu.brand.path,	element: <DashboardHeader />},
	{ path: dashboardPagesMenu.category.path,	element: <DashboardHeader />},		
	{ path: dashboardPagesMenu.subCategory.path,	element: <DashboardHeader />},		
	{ path: dashboardPagesMenu.avatar.path,	element: <DashboardHeader />},  
	{ path: dashboardPagesMenu.customizationPointView.path,	element: <DashboardHeader />},
	{ path: dashboardPagesMenu.customizationPointItemsView.path, element: <DashboardHeader />},
	{ path: dashboardPagesMenu.customizationPointItemsView.path, element: <DashboardHeader />},
	{ path: dashboardPagesMenu.mappingTable.path, element: <DashboardHeader />},
	{ path: dashboardPagesMenu.pincode.path, element: <DashboardHeader />},
	{ path: dashboardPagesMenu.event.path, element: <DashboardHeader />},
	{ path: dashboardPagesMenu.promoCode.path, element: <DashboardHeader />},
	{ path: dashboardPagesMenu.transaction.path, element: <DashboardHeader />},
	{ path: dashboardPagesMenu.productCodeInventory.path, element: <DashboardHeader />},
];

export default headers;
