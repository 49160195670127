import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const ProductCodeInventoryService = {
    addEditProductCodesInventory,
    getProductCodeInventoryById,
    getAllProductCodeInventory,
    fetchAllProductCodeInventory,
    deleteProductCodeInventoryById,    
};


function addEditProductCodesInventory(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateProductCodeInventory + id : apiUrl.createProductCodeInventory, {...data}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getProductCodeInventoryById(id:any) {
    return httpCommon.get(apiUrl.getProductCodeInventoryById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllProductCodeInventory() {
    return httpCommon.get(apiUrl.getAllProductCodeInventory, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllProductCodeInventory(data: any) {
    return httpCommon.post(apiUrl.fetchAllProductCodeInventory,data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteProductCodeInventoryById(id:any) {
    return httpCommon.post(apiUrl.deleteProductCodeInventory + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}