export const baseURL: any = 'http://142.93.220.170:4000/';

export const profileUrl = {
    loginUser: 'user/loginUser',
}

export const avtarUrl = {
    createAvatar: 'avatar/createAvatar',
    getAllAvatar: 'avatar/getAllAvatar',
    getAvatarById: 'avatar/getAvatarById/',
    updateAvatarById: 'avatar/updateAvatarById/',
    deleteAvatarById: 'avatar/deleteAvatarById/'
}



export const apiUrl = {

    /* Brand API */
    createBrand: 'brand/createBrand',
    getAllBrand: 'brand/getAllBrands',
    fetchAllBrands: 'brand/fetchAllBrands',
    getBrandById: 'brand/getBrandById/',
    updateBrandById: 'brand/updateBrandById/',
    deleteBrandById: 'brand/deleteBrandById/',
    uploadBrandImageDataById: 'brand/uploadBrandImageDataById/',
    deleteBrandImageDataById: 'brand/deleteBrandImageDataById/',
    
    /* Customization Point API */
    createCustomizationPoint: 'customization/createCustomization',
    getCustomizationPointById: 'customization/getCustomizationById/',
    updateCustomizationPoint: 'customization/updateCustomizationById/',
    getAllcustomizationPoint: 'customization/getAllCustomizations',
    fetchAllCustomizations: 'customization/fetchAllCustomizations',
    deleteCustomizationPointById: 'customization/deleteCustomizationById/',
    uploadCustomizationPointImageById: 'customization/uploadCustomizationImageById/',
    deleteCustomizationPointImageById: 'customization/deleteCustomizationImageById/',

    /* Customization Point Item API */
    createCustomizationPointItem: 'customizationPointItem/createCustomizationPointItem',
    getCustomizationPointItemById: 'customizationPointItem/getCustomizationPointItemById/',
    updateCustomizationPointItem: 'customizationPointItem/updateCustomizationPointItem/',
    getAllcustomizationPointItem: 'customizationPointItem/getAllCustomizationPointItem',
    fetchAllCustomizationPointItem: 'customizationPointItem/fetchAllCustomizationPointItem',
    deleteCustomizationPointItemById: 'customizationPointItem/deleteCustomizationPointItem/',
    
    /* Product Code API */
    createProductCode: 'productCode/createProductCode',
    getProductCodeById: 'productCode/getProductCodeById/',
    updateProductCode: 'productCode/updateProductCodeById/',
    getAllProductCode: 'productCode/getAllProductCode',
    fetchAllProductCode: 'productCode/fetchAllProductCode',
    deleteProductCodeById: 'productCode/deleteProductCodeById/',
    uploadProductCodeImageById: 'productCode/uploadProductCodeImageDataById/',
    deleteProductCodeImageById: 'productCode/deleteProductCodeImageDataById/',
 
    /* Category API */
    createCategory: 'category/createCategory',
    updateCategoryById: 'category/updateCategoryById/',
    getAllCategory: 'category/getAllCategory',
    fetchAllCategories: 'category/fetchAllCategories',
    getCategoryById: 'category/getCategoryById/',
    deleteCategoryById: 'category/deleteCategoryById/',
    uploadCategoryImageDataById: 'category/uploadCategoryImageDataById/',
    deleteCategoryImageDataById: 'category/deleteCategoryImageDataById/',
    
    /* Sub Category API */
    createSubCategory: 'subCategory/createSubCategory',
    getAllSubCategory: 'subCategory/getAllSubCategory',
    fetchAllSubCategories: 'subCategory/fetchAllSubCategories',
    getSubCategoryById: 'subCategory/getSubCategoryById/',
    updateSubCategoryById: 'subCategory/updateSubCategoryById/',
    deleteSubCategoryById: 'subCategory/deleteSubCategoryById/',
    uploadSubCategoryImageDataById: 'subCategory/uploadSubCategoryImageDataById/',
    deleteSubCategoryImageDataById: 'subCategory/deleteSubCategoryImageDataById/',

     /* Mapping Table Api */
     createMappingTable: 'mapping/createMappingTable',
     getMappingTableById: 'mapping/getMappingById/',
     fetchAllMappingTable: 'mapping/fetchAllMappingTable',
     updateMappingTableById: 'mapping/updateMappingTableById/',
     getAllMappingTable: 'mapping/getAllMappingTable',
     deleteteMappingTableById: 'mapping/deleteMappingTableById/',

    /* Avatar Table Api */
     createAvatar: 'avatar/createAvatar',
     getAllAvatar: 'avatar/getAllAvatar',
     fetchAllAvatar: 'avatar/fetchAllAvatar',
     getAvatarById: 'avatar/getAvatarById/',
     updateAvatarById: 'avatar/updateAvatarById/',
     deleteAvatarById: 'avatar/deleteAvatarById/',

     /* Pincode Table Api */
     createPincode: 'pincode/createPincode',
     getAllPincode: 'pincode/getAllPincode',
     fetchAllPincode: 'pincode/fetchAllPincode',
     getPincodeById: 'pincode/getPincodeById/',
     updatePincodeById: 'pincode/updatePincodeById/',
     deletePincodeById: 'pincode/deletePincodeById/',
 
     /* Event Table Api */
     createEvent: 'event/createEvent',
     getAllEvent: 'event/getAllEvents',
     fetchAllEvent: 'event/fetchAllEvents',
     getEventById: 'event/getEventById/',
     updateEventById: 'event/updateEventById/',
     deleteEventById: 'event/deleteEventById/',
     
     /* PromoCode Table Api */
     createPromoCode: 'promocode/createPromocode',
     getAllPromoCode: 'promocode/getAllPromocode',
     fetchAllPromoCode: 'promocode/fetchAllPromocode',
     getPromoCodeById: 'promocode/getPromocodeById/',
     updatePromoCodeById: 'promocode/updatePromocodeById/',
     deletePromoCodeById: 'promocode/deletePromocodeById/',

     /*Transcation Table Api */
     createTransaction: 'transaction/createTransaction',
     getAllTransaction: 'transaction/getAllTransaction',
     fetchAllTransaction: 'transaction/fetchAllTransactions',
     getTransactionById: 'transaction/getTransactionById/',
     updateTransactionById: 'transaction/updateTransactionById/',
     deleteTransactionById: 'transaction/deleteTransactionById/',

     /*Textile Table Api */
     createTexttile: 'textile/createTextile',
     getAllTexttile: 'textile/getAllTextiles',
     getTexttileById: 'textile/getTextileById/',
     fetchAllTesttile: 'textile/fetchAllTextiles',
     updateTextTitle: 'textile/updateTextileById/',
     deleteTextTitle: 'textile/deleteTextileImageById/',

     /*Product Code Inventory */
     createProductCodeInventory: 'productCodeInventory/createProductCodeInventory',
     getAllProductCodeInventory: 'productCodeInventory/findAllProductCodeInventories',
     fetchAllProductCodeInventory: 'productCodeInventory/fetchAllProductCodeInventories',
     getProductCodeInventoryById: 'productCodeInventory/getProductCodeInventoryById/',
     updateProductCodeInventory: 'productCodeInventory/updateProductCodeInventory/',
     deleteProductCodeInventory: 'productCodeInventory/deleteProductCodeInventory/',
}

export const routingConstant = {
    customizationPoint: '/customization-point',
    customizationPointView: '/customization-point-view',
    customizationItem: '/customization-point-items',
    customizationItemView: '/customization-point-items-view',
    productCode: '/product-code',
    productCodeView: '/product-code-view',
    avatar : "/avatar",
    avatarView : "/avatar-view",
    brand : "/brand",
    brandView : "/brand-view",
    category : "/category",
    categoryView : "/category-view",
    subCategory : "/subCategory",
    subCategoryView : "/subCategory-view",
    mappingTable: "/mappingTable",
    mappingTableView: '/mappingTable-view',
    pincode: "/pincode",
    pincodeView: '/pincode-view',
    event: "/event",
    eventView: '/event-view',
    productCodeInvetory: '/productcodeinventory',
    productCodeInvetoryView: '/product-code-inventory-view',
    promoCode: "/promocode",
    promoCodeView: '/promocode-view',
}