import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const BrandService = {
    addEditBrand,
    getBrandById,
    getAllBrands,
    fetchAllBrands,
    deleteBrandById,
    uploadBrandImage,
    deleteBrandImage,
};

function addEditBrand(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateBrandById + id : apiUrl.createBrand, {...data}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getBrandById(id:any) {
    return httpCommon.get(apiUrl.getBrandById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteBrandById(id:any) {
    return httpCommon.post(apiUrl.deleteBrandById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllBrands() {
    return httpCommon.get(apiUrl.getAllBrand, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllBrands(data: any) {
    return httpCommon.post(apiUrl.fetchAllBrands, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function uploadBrandImage(data:any,id:any) {
    return httpCommon.post(apiUrl.uploadBrandImageDataById + id, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteBrandImage(data:any, id:any) {
    return httpCommon.post(apiUrl.deleteBrandImageDataById + id, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}