import { baseURL, routingConstant } from './../../../core/constant';
import React, { useState, useEffect, useRef } from 'react'
import { WithContext as ReactTags } from 'react-tag-input';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Select from '../../../components/bootstrap/forms/Select';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Icon from '../../../components/icon/Icon';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { CategoryService, SubCategoryService, BrandService, CommonService } from '../../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { CONSTANT } from '../../../core/static-constant';
import NoImage from '../../../assets/noImage.png';
import { toast } from 'react-toastify';

const AddEditBrand = () => {
    const imgPath = baseURL + CONSTANT.IMG_PATH.BRAND;
    const params = useParams();
    const navigate = useNavigate();
    const img1Ref = useRef<any>(null)
    const img2Ref = useRef<any>(null)
    const img3Ref = useRef<any>(null)
    const img4Ref = useRef<any>(null)
    const [isInitialized, setIsInitialized] = useState(false)
    const [brandId, setBrandId] = useState("")
    const [brandObj, setBrandObj] = useState<any>({
        categoryId: "",
        subCategoryId: "",
        brandName: "",
        brandType: "",
        brandDescription: "",
        brandLogoUrl: "",
        status: "Active",
        createdBy: "",
        pointDiscountLimit: "",
        keywords: [],
    });
    const [categoryData, setCategoryData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [brandData, setBrandData] = useState({});
    const [BrandImage1Name, setBrandImage1Name] = useState("")
    const [BrandImage2Name, setBrandImage2Name] = useState("")
    const [BrandImage3Name, setBrandImage3Name] = useState("")
    const [BrandImage4Name, setBrandImage4Name] = useState("")
    const [isInvalidForm, setIsInvalidForm] = useState(false)

    const getAllCategories = async () => {
        try {
            let data: any = await CategoryService.getAllCategories()
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data.data
                setCategoryData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error(CONSTANT.ERROR_OCCURRED);
        }
    }

    const getAllSubCategory = async () => {
        try {
            let data: any = await SubCategoryService.getAllSubCategory()
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data.data
                setSubCategoryData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error(CONSTANT.ERROR_OCCURRED)
        }
    }


    useEffect(() => {
        if (!isInitialized) {
            setIsInitialized(true)
        } else {
            const init = async () => {
                const userData: any = await JSON.parse(localStorage.getItem("userData") || "")
                await setBrandObj({ ...brandObj, createdBy: userData.id })
                await getAllCategories()
                await getAllSubCategory()
                if (params && params.id) {
                    await setBrandId(params.id)
                    await getBrandById(params.id)
                }
            }
            init()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [isInitialized])

    const getBrandById = async (id: any) => {
        let data: any = await BrandService.getBrandById(id)
        if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data.data
            setBrandData(responseData);
            resetBrandObj(responseData)
        } else {
            toast.error(data.message);
        }
    }

    const resetBrandObj = (data: any) => {
        setBrandObj({
            ...brandObj,
            categoryId: data?.categoryId?._id || "",
            subCategoryId: data?.subCategoryId?._id || "",
            brandName: data.brandName || "",
            brandType: data.brandType || "",
            brandDescription: data.brandDescription || "",
            brandLogoUrl: data.brandLogoUrl || "",
            status: data.status || "Active",
            pointDiscountLimit: data.pointDiscountLimit || "",
            keywords: data.keywords || [],
        })
        setBrandImage1Name(data?.brandImage1?.filename || "")
        setBrandImage2Name(data?.brandImage2?.filename || "")
        setBrandImage3Name(data?.brandImage3?.filename || "")
        setBrandImage4Name(data?.brandImage4?.filename || "")
    }

    const onSaveHandleClick = async () => {
        if (await CommonService.checkIsInvalidForm(CONSTANT.BRAND_REQUIRED_FIELD, { ...brandObj })) {
            setIsInvalidForm(true)
        } else if (!CommonService.validURL(brandObj.brandLogoUrl)) {
            setIsInvalidForm(true)
        } else {
            setIsInvalidForm(false)
            try {
                let data: any = await BrandService.addEditBrand(brandObj, brandId)
                if (data && data.status === CONSTANT.SUCCESS) {
                    resetBrandObj({})
                    navigate(routingConstant.brandView)
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                toast.error(CONSTANT.ERROR_OCCURRED)
            }
        }
    }

    const onCancelClick = () => {
        setBrandId("")
        resetBrandObj({})
        navigate(routingConstant.brandView)
    }

    const onUploadBrandImage = async (event: any, field: any) => {        
        let formData = new FormData();
        formData.append(field, event.target.files[0])
        try {
            let data: any = await BrandService.uploadBrandImage(formData, brandId)
            if (data && data.status === CONSTANT.SUCCESS) {
                getBrandById(brandId)
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error(CONSTANT.ERROR_OCCURRED)
        }
    }

    const onDeleteImageClick = async (fieldName: string) => {
        let obj: any = { fieldName: fieldName }
        if (fieldName == "brandImage1") {
            obj.filename = BrandImage1Name
        } else if (fieldName == "brandImage2") {
            obj.filename = BrandImage2Name
        } else if (fieldName == "brandImage3") {
            obj.filename = BrandImage3Name
        } else {
            obj.filename = BrandImage4Name
        }
        try {
            let data: any = await BrandService.deleteBrandImage(obj, brandId)
            if (data && data.status === CONSTANT.SUCCESS) {
                getBrandById(brandId);
                toast.success(data.message)
            } else {
                toast.error(data.message)
            }
        } catch (error) {
            toast.error(CONSTANT.ERROR_OCCURRED)
        }
    }

    const handleDelete = (i: any) => {
        let tags = brandObj.keywords
        setBrandObj({ ...brandObj, keywords: tags.filter((tag: any, index: any) => index !== i) })
    };

    const handleAddition = (tag: any) => {
        let tags = brandObj.keywords
        setBrandObj({ ...brandObj, keywords: [...tags, tag] })
    };

    const handleDrag = (tag: any, currPos: any, newPos: any) => {
        let tags = brandObj.keywords
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setBrandObj({ ...brandObj, keywords: newTags })
    };

    return (
        <div className='page container-xxl align-items-center'>
            <Card className='w-100'>
                <CardBody>
                    <h3 className='text-center mt-3 mb-5 text-primary'>
                        <strong> Add Brand</strong>
                    </h3>
                    <div className='row g-4'>
                        <div className='col-sm-6 col-md-4'>
                            <FormGroup id='brandName' label='Brand Name*' isFloating>
                                <>
                                    <Input
                                        placeholder='Brand Name'
                                    className={`${isInvalidForm && CommonService.isUndefinedOrNull(brandObj.brandName) ? 'border border-danger': ''}`}
                                    value={brandObj.brandName}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, brandName: e.target.value })}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.brandName) &&
                                        <p className="text-danger ms-2"><small>Name is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <FormGroup id='brandType' label='Brand Type*' isFloating>
                                <>
                                    <Input
                                        placeholder='Brand Type'
                                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(brandObj.brandType) ? 'border border-danger': ''}`}
                                        value={brandObj.brandType}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, brandType: e.target.value })}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.brandType) &&
                                        <p className="text-danger ms-2"><small>Type is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <FormGroup id='pointDiscountLimit' label='Point Discount*' isFloating>
                                <>
                                    <Input
                                        placeholder='Point Discount'
                                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(brandObj.pointDiscountLimit) ? 'border border-danger': ''}`} value={brandObj.pointDiscountLimit}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, pointDiscountLimit: e.target.value })}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.pointDiscountLimit) &&
                                        <p className="text-danger ms-2"><small>Point Discount limit is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <FormGroup id='categoryId' label='Category*' isFloating>
                                <>
                                    <Select ariaLabel='Board select' value={brandObj.categoryId}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, categoryId: e.target.value })} >
                                            <option  value={""} disabled> Select Category Name </option>
                                        {
                                            categoryData.map((element: any, i: any) => {
                                                return <option key={i} value={element._id} > {element.categoryName} </option>
                                            })
                                        }
                                    </Select>
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.categoryId) &&
                                        <p className="text-danger ms-2"><small>Category Name is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <FormGroup id='subCategoryId' label='Sub Category*' isFloating>
                                <>
                                    <Select ariaLabel='Board select' value={brandObj.subCategoryId}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, subCategoryId: e.target.value })} >
                                            <option  value={""} disabled> Select Sub Category Name </option>
                                        {
                                            subCategoryData.map((element: any, i: any) => {
                                                return <option key={i} value={element._id} > {element.subCatgeoryName} </option>
                                            })
                                        }
                                    </Select>
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.subCategoryId) &&
                                        <p className="text-danger ms-2"><small>Sub Category Name is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <FormGroup id='status' label='Status' isFloating>
                                <>
                                    <Select ariaLabel='Board select' value={brandObj.status}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, status: e.target.value })} >
                                            <option  value={""} disabled> Select Status </option>
                                            {
                                                CONSTANT.STATUS_LIST.map((list:any) => <option key={list.id} value={list.id} > {list.value} </option>)
                                            }
                                    </Select>
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.status) &&
                                        <p className="text-danger ms-2"><small>Status is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='brandLogoUrl' label='Brand Logo*' isFloating>
                                <>
                                    <Input
                                        placeholder='Brand Logo'
                                        className={`${isInvalidForm && CommonService.isUndefinedOrNull(brandObj.brandLogoUrl) ? 'border border-danger': ''}`} value={brandObj.brandLogoUrl}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, brandLogoUrl: e.target.value })}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.brandLogoUrl) &&
                                        <p className="text-danger ms-2"><small>Brand Url is required</small></p>
                                    }
                                    {
                                        !CommonService.isUndefinedOrNull(brandObj.brandLogoUrl) && !CommonService.validURL(brandObj.brandLogoUrl) &&
                                        <p className="text-danger ms-2"><small>Please enter valid URL</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <FormGroup id='brandDescription' label='Brand Description*' isFloating>
                                <>
                                    <Textarea
                                        rows={3}
                                        className="mh-90"
                                        placeholder='Brand Description'
                                        value={brandObj.brandDescription}
                                        onChange={(e: any) => setBrandObj({ ...brandObj, brandDescription: e.target.value })}
                                    />
                                    {
                                        isInvalidForm && CommonService.isUndefinedOrNull(brandObj.brandDescription) &&
                                        <p className="text-danger ms-2"><small>Brand Description is required</small></p>
                                    }
                                </>
                            </FormGroup>
                        </div>
                        <div className='col-sm-12 col-md-12'>
                            <FormGroup id='Keywords' isFloating>
                                <>
                                    <ReactTags
                                        classNames={{ tagInputField: "form-control w-100", tagInput: "w-100" }}
                                        tags={brandObj.keywords}
                                        placeholder="Please enter keyword"
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        handleDrag={handleDrag}
                                        inputFieldPosition="top"
                                    />                                 
                                </>
                            </FormGroup>
                        </div>
                    </div>
                    <div>
                        <Button
                            type="button"
                            color='info'
                            className='w-25 mt-5'
                            onClick={() => { onSaveHandleClick(); }}

                        >
                            Save
                        </Button>
                        <Button
                            type="button"
                            color='danger'
                            className='w-25 mt-5 float-end'
                            onClick={(event: any) => { event.preventDefault(); onCancelClick() }}
                        >
                            Cancel
                        </Button>
                    </div>
                    {
                        brandId &&
                        <div className='row text-center'>
                            <div className='col-6 mt-5'>
                                <div className='d-inline-flex'>
                                    <img className='cursor-pointer border border-light' src={BrandImage1Name && imgPath + BrandImage1Name || NoImage} alt="img1" height={150} width={150} onClick={() => img1Ref.current.click()} />
                                    {
                                        BrandImage1Name &&
                                        <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"} onClick={() => onDeleteImageClick("brandImage1")} />
                                    }
                                </div>
                                <Input
                                    hidden={!!brandId}
                                    ref={img1Ref}
                                    type={'file'}
                                    autoComplete='photo'
                                    onChange={(e: any) => { setBrandObj({ ...brandObj, brandImage1: e.target.files[0] }); onUploadBrandImage(e, "brandImage1") }}
                                />
                            </div>
                            <div className='col-6 mt-5'>
                                <div className='d-inline-flex'>
                                    <img className='cursor-pointer border border-light' src={BrandImage2Name && imgPath + BrandImage2Name || NoImage} alt="img2" height={150} width={150} onClick={() => img2Ref.current.click()} />
                                    {
                                        BrandImage2Name &&
                                        <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"} onClick={() => onDeleteImageClick("brandImage2")} />
                                    }
                                </div>
                                <Input
                                    hidden={!!brandId}
                                    type={'file'}
                                    ref={img2Ref}
                                    autoComplete='photo'
                                    onChange={(e: any) => { setBrandObj({ ...brandObj, brandImage2: e.target.files[0] }); onUploadBrandImage(e, "brandImage2") }}
                                />
                            </div>
                            <div className='col-6 mt-5'>
                                <div className='d-inline-flex'>
                                    <img className='cursor-pointer border border-light' src={BrandImage3Name && imgPath + BrandImage3Name || NoImage} alt="img2" height={150} width={150} onClick={() => img3Ref.current.click()} />
                                    {
                                        BrandImage3Name &&
                                        <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"} onClick={() => onDeleteImageClick("brandImage3")} />
                                    }
                                </div>
                                <Input
                                    hidden={!!brandId}
                                    type={'file'}
                                    ref={img3Ref}
                                    autoComplete='photo'
                                    onChange={(e: any) => { setBrandObj({ ...brandObj, brandImage3: e.target.files[0] }); onUploadBrandImage(e, "brandImage3") }}
                                />
                            </div>
                            <div className='col-6 mt-5'>
                                <div className='d-inline-flex'>
                                    <img className='cursor-pointer border border-light' src={BrandImage4Name && imgPath + BrandImage4Name || NoImage} alt="img2" height={150} width={150} onClick={() => img4Ref.current.click()} />
                                    {
                                        BrandImage4Name &&
                                        <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"} onClick={() => onDeleteImageClick("brandImage4")} />
                                    }
                                </div>
                                <Input
                                    hidden={!!brandId}
                                    type={'file'}
                                    ref={img4Ref}
                                    autoComplete='photo'
                                    onChange={(e: any) => { setBrandObj({ ...brandObj, brandImage4: e.target.files[0] }); onUploadBrandImage(e, "brandImage4") }}
                                />
                            </div>
                        </div>
                    }
                </CardBody>
            </Card>
        </div >
    )
}

export default AddEditBrand;