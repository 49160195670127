import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const PromoCodeService = {
    addEditPromoCode,
    getPromoCodeById,
    getAllPromoCode,
    deletePromoCodeById,
};
function addEditPromoCode(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updatePromoCodeById + id : apiUrl.createPromoCode, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getPromoCodeById(id:any) {
    return httpCommon.get(apiUrl.getPromoCodeById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllPromoCode(data: any) {
    return httpCommon.post(apiUrl.fetchAllPromoCode, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deletePromoCodeById(id:any) {
    return httpCommon.post(apiUrl.deletePromoCodeById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}