export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},	
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',		
	},
	avatar: {
		id: 'avatar',
		text: 'Avatar',
		path: '/avatar-view',
		icon: 'Insert Emoticon',
	},
	product: {
		id: 'product code',
		text: 'Product Code',
		path: '/product-code-view',
		icon: 'Games',		
	},
	brand: {
		id: 'brand',
		text: 'Brand',
		path: '/brand-view',
		icon: 'Branding Watermark',		
	},	
	category: {
		id: 'category',
		text: 'Category',
		path: '/category-view',
		icon: 'List',		
	},
	subCategory: {
		id: 'subCategory',
		text: 'Sub Category',
		path: '/subcategory-view',
		icon: 'List',		
	},
	customizationPointView: {
		id: 'customizationpoint',
		text: 'Customization Point',
		path: '/customization-point-view',
		icon: 'DashboardCustomize',
	},
	customizationPointItemsView: {
		id: 'customizationpointItems',
		text: 'Customization Point Items',
		path: '/customization-point-items-view',
		icon: 'ViewArray',		
	},
	mappingTable: {
		id: 'mappingtable',
		text: 'Mapping Table',
		path: '/mappingTable-view',
		icon: 'Web Asset',		
	},
	pincode: {
		id: 'Pincode',
		text: 'Pincode',
		path: '/pincode-view',
		icon: 'Fiber Pin',		
	},
	event: {
		id: 'Event',
		text: 'Event',
		path: '/event-view',
		icon: 'Event',		
	},
	promoCode: {
		id: 'PromoCode',
		text: 'PromoCode',
		path: '/promocode-view',
		icon: 'Window',		
	},
	transaction: {
		id: 'Transaction',
		text: 'Transaction',
		path: '/transaction-view',
		icon: 'Qr Code',		
	},
	productCodeInventory: {
		id: 'ProductCodeInventory',
		text: 'Product Code Inventory',
		path: '/product-code-inventory-view',
		icon: 'Inventory',		
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
