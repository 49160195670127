import React, { useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';

const User = () => {
	const { userData, setUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	const handleClick = () => {
		if (setUser) {
			setUser('');
		}
		localStorage.removeItem("userData")
		localStorage.removeItem("authToken")
		navigate("/auth-pages/login");
	}

	let data: any = localStorage.getItem("userData")
	data = JSON.parse(data)

	return (
		<>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon='AccountBox'
					>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>
			<Collapse isOpen={collapseStatus} className='user-menu'>
				<NavigationLine />
			</Collapse>
			<nav aria-label='aside-bottom-user-menu-2'>
				<div className='navigation'>
					<div
						role='presentation'
						className='navigation-item cursor-pointer'
						onClick={() => handleClick()}>
						<span className='navigation-link navigation-link-pill'>
							<span className='navigation-link-info'>
								<Icon icon='Logout' className='navigation-icon' />
								<span className='navigation-text'>
									{t('Logout') as ReactNode}
								</span>
							</span>
						</span>
					</div>
				</div>
			</nav>
		</>
	);
};

export default User;
