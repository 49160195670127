import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const CustomizationPointItemService = {
    addEditCustomizationPointItems,
    getCustomizationPointItemById,
    getAllCustomizationPointItems,
    fetchAllCustomizationPointItem,
    deleteCustomizationPointItem,
};


function addEditCustomizationPointItems(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateCustomizationPointItem + id : apiUrl.createCustomizationPointItem, {...data}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getCustomizationPointItemById(id:any) {
    return httpCommon.get(apiUrl.getCustomizationPointItemById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllCustomizationPointItems() {
    return httpCommon.get(apiUrl.getAllcustomizationPointItem).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllCustomizationPointItem(data: any) {
    return httpCommon.post(apiUrl.fetchAllCustomizationPointItem, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteCustomizationPointItem(id:any) {
    return httpCommon.post(apiUrl.deleteCustomizationPointItemById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}