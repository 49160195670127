import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {	
	dashboardPagesMenu
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import AddEditAvatar from '../pages/presentation/avatar/AddEditAvatar';
import AddEditCategory from '../pages/presentation/category/AddEditCategory';
import AddEditProduct from '../pages/presentation/product/AddEditProduct';
import AddEditBrand from '../pages/presentation/brand/AddEditBrand';
import AddEditSubCategory from '../pages/presentation/subCategory/AddEditSubCategory';

const LANDING = {
	AVTAR: lazy(() => import('../pages/presentation/avatar/avatar')),
	SUBCATEGORY: lazy(() => import('../pages/presentation/subCategory/SubCategory')),
	DASHBOARD: lazy(() =>import('../pages/presentation/dashboard/dashboard')),
	PRODUCT: lazy(() => import('../pages/presentation/product/Product')),
	CATEGORY: lazy(() => import('../pages/presentation/category/Category')), 
	Brand: lazy(() => import('../pages/presentation/brand/brand')),
	CUSTOMIZATIONPOINT: lazy(() => import('../pages/presentation/customization-point/CustomizationPoints')),
	ADD_EDIT_CUSTOMIZATIONPOINT: lazy(() => import('../pages/presentation/customization-point/AddEditCustomizationPoints')),
	CUSTOMIZATIONPOINT_ITEMS: lazy(() => import('../pages/presentation/customization-point-items/CustomizationPointItems')),
	ADD_EDIT_CUSTOMIZATIONPOINT_ITEMS: lazy(() => import('../pages/presentation/customization-point-items/AddEditCustomizationPointItems')),
	MAPPING: lazy(() => import('../pages/presentation/mappingTable/mappingTable')),
	ADD_EDIT_MAPPING: lazy(() => import('../pages/presentation/mappingTable/AddEditMappingTable')),
	PINCODE: lazy(() => import('../pages/presentation/pincode/Pincode')),
	ADD_EDIT_PINCODE: lazy(() => import('../pages/presentation/pincode/AddEditPincode')),
	EVENT: lazy(() => import('../pages/presentation/event/Event')),
	ADD_EDIT_EVENT: lazy(() => import('../pages/presentation/event/AddEditEvent')),
	PROMO_CODE: lazy(() => import('../pages/presentation/promo-code/PromoCode')),
	ADD_EDIT_PROMO_CODE: lazy(() => import('../pages/presentation/promo-code/AddEditPromoCode')),
	TRANSACTION: lazy(() => import('../pages/presentation/transaction/ViewTransaction')),
	PRODUCT_CODE_INVENTORY: lazy(() => import('../pages/presentation/productCodeInventory/productCodeInventory')),
	ADD_EDIT_PRODUCT_CODE_INVENTORY: lazy(() => import('../pages/presentation/productCodeInventory/AddEditProductCodeInventory'))
};

const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />
	},
	{
		path: dashboardPagesMenu.product.path,
		element: <LANDING.PRODUCT />
	},
	{
		path: dashboardPagesMenu.brand.path,
		element: <LANDING.Brand />,
	},
	{
		path: dashboardPagesMenu.category.path,
		element: <LANDING.CATEGORY />,
	},
	{
		path: dashboardPagesMenu.subCategory.path,
		element: <LANDING.SUBCATEGORY />,
	},
	{
		path: dashboardPagesMenu.avatar.path,
		element: <LANDING.AVTAR />,
	},
	{
		path: dashboardPagesMenu.customizationPointView.path,
		element: <LANDING.CUSTOMIZATIONPOINT />,
	},
	{
		path: dashboardPagesMenu.customizationPointItemsView.path,
		element: <LANDING.CUSTOMIZATIONPOINT_ITEMS />,
	},
	{
		path: dashboardPagesMenu.mappingTable.path,
		element: <LANDING.MAPPING />,
	},
	{
		path: dashboardPagesMenu.pincode.path,
		element: <LANDING.PINCODE />,
	},
	{
		path: dashboardPagesMenu.event.path,
		element: <LANDING.EVENT />,
	},
	{
		path: dashboardPagesMenu.promoCode.path,
		element: <LANDING.PROMO_CODE />,
	},
	{
		path: dashboardPagesMenu.transaction.path,
		element: <LANDING.TRANSACTION />,
	},	
	{
		path: dashboardPagesMenu.productCodeInventory.path,
		element: <LANDING.PRODUCT_CODE_INVENTORY />,
	},
	/** ************************************************** */

	/**
	 * Auth Page
	 */	
	{
		path: "/auth-pages/login",
		element: <Login />,
	}, 
	{
		path: "/category",
		element: <AddEditCategory />
	},
	{
		path: "/category/:id",
		element: <AddEditCategory />
	},
	{
		path: "/subcategory",
		element: <AddEditSubCategory />
	},
	{
		path: "/subcategory/:id",
		element: <AddEditSubCategory />
	},
	{
		path: "/product-code",
		element: <AddEditProduct />
	},
	{
		path: "/product-code/:id",
		element: <AddEditProduct />
	},
	{
		path: "/brand",
		element: <AddEditBrand />
	},
	{
		path: "/brand/:id",
		element: <AddEditBrand />
	},
	{
		path: "/avatar",
		element: <AddEditAvatar />
	},
	{
		path: "/avatar/:id",
		element: <AddEditAvatar />
	},
	{
		path: '/customization-point',
		element: <LANDING.ADD_EDIT_CUSTOMIZATIONPOINT />,	
	},
	{
		path: '/customization-point/:id',
		element: <LANDING.ADD_EDIT_CUSTOMIZATIONPOINT />,	
	},
	{
		path: '/customization-point-items',
		element: <LANDING.ADD_EDIT_CUSTOMIZATIONPOINT_ITEMS />,	
	},
	{
		path: '/customization-point-items/:id',
		element: <LANDING.ADD_EDIT_CUSTOMIZATIONPOINT_ITEMS />,	
	},
	{
		path: "/addeditcategory",
		element: <AddEditCategory />
	},
	{
		path: '/mappingTable',
		element: <LANDING.ADD_EDIT_MAPPING />,	
	},
	{
		path: '/mappingTable/:id',
		element: <LANDING.ADD_EDIT_MAPPING />,	
	},
	{
		path: '/pincode',
		element: <LANDING.ADD_EDIT_PINCODE />,	
	},
	{
		path: '/pincode/:id',
		element: <LANDING.ADD_EDIT_PINCODE />,	
	},
	{
		path: '/event',
		element: <LANDING.ADD_EDIT_EVENT />,	
	},
	{
		path: '/event/:id',
		element: <LANDING.ADD_EDIT_EVENT />,	
	},
	{
		path: '/promocode',
		element: <LANDING.ADD_EDIT_PROMO_CODE />,	
	},
	{
		path: '/promocode/:id',
		element: <LANDING.ADD_EDIT_PROMO_CODE />,	
	},
	{
		path: '/productcodeinventory/',
		element: <LANDING.ADD_EDIT_PRODUCT_CODE_INVENTORY />,	
	},
	{
		path: '/productcodeinventory/:id',
		element: <LANDING.ADD_EDIT_PRODUCT_CODE_INVENTORY />,	
	}
	/** ************************************************** */
];
const contents = [...presentation];

export default contents;