import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const CustomizationPointService = {
    addEditCustomizationPoints,
    getCustomizationPointById,
    getAllCustomizationPoints,
    fetchAllCustomizations,
    deleteCustomizationPointById,
    uploadCustomizationPointImage,
    deleteCustomizationPointImage
};


function addEditCustomizationPoints(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateCustomizationPoint + id : apiUrl.createCustomizationPoint, {...data}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getCustomizationPointById(id:any) {
    return httpCommon.get(apiUrl.getCustomizationPointById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllCustomizationPoints() {
    return httpCommon.get(apiUrl.getAllcustomizationPoint, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllCustomizations(data: any) {
    return httpCommon.post(apiUrl.fetchAllCustomizations, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteCustomizationPointById(id:any) {
    return httpCommon.post(apiUrl.deleteCustomizationPointById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function uploadCustomizationPointImage(data:any,id:any) {
    return httpCommon.post(apiUrl.uploadCustomizationPointImageById + id, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteCustomizationPointImage(id:any) {
    return httpCommon.post(apiUrl.deleteCustomizationPointImageById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}