import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import httpCommon from '../../../core/http-common';
import { profileUrl } from '../../../core/constant';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
    const [userEmailId, setUserEmailId] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);


	
	const loginUser = (e: any) => {
		const obj = {
		  userEmailId: userEmailId,
		  password: password,
		};
		httpCommon.post(profileUrl.loginUser, obj).then(
		  async(data: any) => {			
			if (data && data.data.status !== 'fail') {			
			  await localStorage.setItem('userData', JSON.stringify(data.data));
			  await localStorage.setItem('authToken', data.data.token.replace("Basic ", ""));
			  window.location.href = "/"		
			}
		  },
		  (error: any) => {
			console.log(error);
		  }
		);
		e.preventDefault();
	  };

	return (
		<PageWrapper
			isProtected={false}
			title='Login'
			className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>									
										{/* <Logo width={200} />									 */}
	 	                           Indyra
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-12 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}												
												className='rounded-1 w-100'
												size='lg'
												>
												Login
											</Button>
										</div>										
									</div>
								</div>
								<LoginHeader />							
								<form className='row g-4'>									
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email or username'
													>
													<Input
														autoComplete='username'													
														value={userEmailId}
													    onChange={(e: any) => setUserEmailId(e.target.value)}
													/>
												</FormGroup>												
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													>
													<Input
														type='password'												
														value={password}
														onChange={(e: any) => setPassword(e.target.value)}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'														
														onClick={loginUser}
														>														
														Continue
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'														
														>
														Login
													</Button>
												)}
											</div>
										</>
									{/* )} 

									{/* BEGIN :: Social Login */}
									{!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div>
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3')}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none')}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
