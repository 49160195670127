import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const CategoryService = {
    addEditCategory,
    getAllCategories,
    fetchAllCategories,
    getCategoryById,
    deleteCategoryById,
    uploadCategoryImage,
    deleteCategoryImage,
};

function addEditCategory(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateCategoryById + id : apiUrl.createCategory, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getCategoryById(id:any) {
    return httpCommon.get(apiUrl.getCategoryById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteCategoryById(id:any) {
    return httpCommon.post(apiUrl.deleteCategoryById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllCategories() {
    return httpCommon.get(apiUrl.getAllCategory, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllCategories(data: any) {
    return httpCommon.post(apiUrl.fetchAllCategories, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function uploadCategoryImage(data:any,id:any) {
    return httpCommon.post(apiUrl.uploadCategoryImageDataById + id, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteCategoryImage(data:any, id:any) {
    return httpCommon.post(apiUrl.deleteCategoryImageDataById + id, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}