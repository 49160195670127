import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const TransactionService = {
    addEditTransaction,
    getTransactionById,
    getAllTransation,
    fetchAllTransaction,
    deleteTransactionById,      
};


function addEditTransaction(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateTransactionById + id : apiUrl.createTransaction, {...data}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getTransactionById(id:any) {
    return httpCommon.get(apiUrl.getTransactionById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllTransation() {
    return httpCommon.get(apiUrl.getAllTransaction, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllTransaction(data: any) {
    return httpCommon.post(apiUrl.fetchAllTransaction, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteTransactionById(id:any) {
    return httpCommon.post(apiUrl.deleteTransactionById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}