import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const MappingTableService = {
    addEditMappingTable,
    getMappingTableById,
    getAllMappingTable,
    fetchAllMappingTable,
    deleteMappingTableById,   
};


function addEditMappingTable(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateMappingTableById + id : apiUrl.createMappingTable, {...data}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getMappingTableById(id:any) {
    return httpCommon.get(apiUrl.getMappingTableById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllMappingTable() {
    return httpCommon.get(apiUrl.getAllMappingTable, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllMappingTable(data: any) {
    return httpCommon.post(apiUrl.fetchAllMappingTable, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteMappingTableById(id:any) {
    return httpCommon.post(apiUrl.deleteteMappingTableById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}