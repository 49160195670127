import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const PincodeService = {
    addEditPincode,
    getPincodeById,
    getAllPincode,
    deletePincodeById,
};
function addEditPincode(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updatePincodeById + id : apiUrl.createPincode, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getPincodeById(id:any) {
    return httpCommon.get(apiUrl.getPincodeById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllPincode() {
    return httpCommon.get(apiUrl.getAllPincode,{}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deletePincodeById(id:any) {
    return httpCommon.post(apiUrl.deletePincodeById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}