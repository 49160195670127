import httpCommon from '../core/http-common';
import { apiUrl } from '../core/constant';

export const AvatarService = {
    addEditAvatar,
    getAvatarById,
    getAllAvatar,
    fetchAllAvatar,
    deleteAvatarById,
};
function addEditAvatar(data:any, id:any) {
    return httpCommon.post((id) ?  apiUrl.updateAvatarById + id : apiUrl.createAvatar, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAvatarById(id:any) {
    return httpCommon.get(apiUrl.getAvatarById + id ).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllAvatar() {
    return httpCommon.get(apiUrl.getAllAvatar, {}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function fetchAllAvatar(data: any) {
    let url = apiUrl.fetchAllAvatar
    return httpCommon.post(url,data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteAvatarById(id:any) {
    return httpCommon.post(apiUrl.deleteAvatarById + id).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}