import { baseURL, routingConstant } from './../../../core/constant';
import React, { useEffect, useState, useRef } from 'react'
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { CONSTANT } from '../../../core/static-constant';
import { CategoryService, CommonService } from '../../../services';
import NoImage from '../../../assets/noImage.png';
import { toast } from 'react-toastify';
import Select from '../../../components/bootstrap/forms/Select';

const AddEditCategory = () => {
  const imgPath = baseURL + CONSTANT.IMG_PATH.CATEGORY;
  const params = useParams();
  const navigate = useNavigate();
  const img1Ref = useRef<any>(null)
  const img2Ref = useRef<any>(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [categoryId, setCategoryId] = useState("")
  const [categoryData, setCategoryData] = useState({})
  const [categoryName, setCategoryName] = useState("")
  const [categoryGender, setCategoryGender] = useState("")
  const [categoryImage1Name, setCategoryImage1Name] = useState("")
  const [categoryImage2Name, setCategoryImage2Name] = useState("")
  const [categoryImage1, setCategoryImage1] = useState<any>([])
  const [categoryImage2, setCategoryImage2] = useState<any>([])
  const [isInvalidForm, setIsInvalidForm] = useState(false)

  useEffect(() => {
    const init = async () => {
        if (!isInitialized) {
            setIsInitialized(true)
        } else {
            if (params && params.id) {
                setCategoryId(params.id)
                getCategoryById(params.id)
            }
        }
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isInitialized])

  const getCategoryById = async (id: any) => {
    let data:any = await CategoryService.getCategoryById(id)
    if (data && data.status === CONSTANT.SUCCESS) {
        const responseData = data.data
        setCategoryData(responseData);
        resetCategoryObj(responseData)
    } else {
        alert(data.message)
    }
  }

  const resetCategoryObj = (data:any) => {
    setCategoryName(data?.categoryName || "")
    setCategoryGender(data?.categoryGender || "")
    setCategoryImage1Name(data?.categoryImage1?.filename || "")
    setCategoryImage2Name(data?.categoryImage2?.filename || "")
  }

  const onSaveHandleClick = async() => {
  if(await CommonService.checkIsInvalidForm(CONSTANT.CATEGORY_REQUIRED_FIELD, { categoryName, categoryGender})) {
      setIsInvalidForm(true)
  } else if(CommonService.isUndefinedOrNull([categoryImage1Name]) && CommonService.isUndefinedOrNull([categoryImage2Name])) {
    setIsInvalidForm(true)
  } else {
      setIsInvalidForm(false)
    let formData = new FormData();
    let object:any = {}
    if(!categoryId) {
        formData.append("categoryName", categoryName)
        formData.append("categoryGender", categoryGender)
        formData.append("categoryImage1", categoryImage1[0])
        formData.append("categoryImage2", categoryImage2[0])
    } else {
      object.categoryName = categoryName
      object.categoryGender = categoryGender
    }
    try {
        let data:any = await CategoryService.addEditCategory(!categoryId ? formData : object,categoryId)
        if (data && data.status === CONSTANT.SUCCESS) {
            resetCategoryObj({})
            navigate(routingConstant.categoryView)
        } else {
            toast.error(data.message)
        }
    } catch (error) {
      toast.error(CONSTANT.ERROR_OCCURRED)
    }
  }
  }

  const onCancelClick = () => {
    setCategoryId("")
    resetCategoryObj({})
    navigate(routingConstant.categoryView)
  }

  const onUploadSubCategoryImage = async (event:any, field:any) => {
    let formData = new FormData();
    formData.append(field,event.target.files[0])
    try {
        let data:any = await CategoryService.uploadCategoryImage(formData,categoryId)
        if (data && data.status === CONSTANT.SUCCESS) {
            getCategoryById(categoryId)              
        } else {
          toast.error(data.message)
        }
    } catch (error) {
      toast.error( CONSTANT.ERROR_OCCURRED )
    }
  }

  const onDeleteImageClick = async (fieldName: string) => {
      let obj:any = {fieldName:fieldName}
      if(fieldName == "categoryImage1 ") {
          obj.filename = categoryImage1Name
      } else {
          obj.filename = categoryImage2Name
      }
      try {
          let data:any = await CategoryService.deleteCategoryImage(obj,categoryId)
          if (data && data.status === CONSTANT.SUCCESS) {
              getCategoryById(categoryId)              
          } else {
            toast.error(data.message)
          }
      } catch (error) {
        toast.error( CONSTANT.ERROR_OCCURRED )
      }
  }


  return (
    <div className='page container-xxl align-items-center'>
      <Card className='w-100'>
        <CardBody>
          <h3 className='text-center mt-3 mb-5 text-primary'>
            <strong> {categoryId ? CONSTANT.UPDATE : CONSTANT.ADD} Category</strong>
          </h3>
          <div className='row g-4'>
            <div className='col-sm-12 col-md-6'>
              <FormGroup id='categoryName' label='Category Name*' isFloating>
              <>
                <Input
                  placeholder='Category Name'
                  className={`${isInvalidForm && CommonService.isUndefinedOrNull(categoryName) ? 'border border-danger': ''}`}
                  value={categoryName}
                  onChange={(e: any) => setCategoryName(e.target.value)}
                  />
                {
                  isInvalidForm && CommonService.isUndefinedOrNull(categoryName) &&
                  <p className="text-danger ms-2"><small>Category Name is required</small></p>
                }
                </>
              </FormGroup>
            </div>
            <div className='col-sm-12 col-md-6'>
              <FormGroup id='categoryGender' label='Category Gender*' isFloating>
                <>                
                  <Select ariaLabel='Board select' className={`${isInvalidForm && CommonService.isUndefinedOrNull(categoryGender) ? 'border border-danger': ''}`} onChange={(e: any) => setCategoryGender(e.target.value)} value={categoryGender} >
                    <option  value={""} disabled> Select Gender </option>
                    <option key={"Male"} value={"Male"} > Male </option>
                    <option key={"Female"} value={"Female"} > Female </option>
                  z </Select>
                {
                  isInvalidForm && CommonService.isUndefinedOrNull(categoryGender) &&
                  <p className="text-danger ms-2"><small>Gender is required</small></p>
                }
                </>
              </FormGroup>
            </div>
            {
              !categoryId && 
              <>
                <div className='col-6 mt-5'>
                  <Input
                    hidden={categoryId ? true : false}
                    type={'file'}
                    autoComplete='photo'
                    onChange={(e: any) => { setCategoryImage1(e.target.files);setCategoryImage1Name(e.target.files[0].name) }}
                  />
                  {
                    isInvalidForm && CommonService.isUndefinedOrNull(categoryImage1Name) &&
                    <p className="text-danger ms-2"><small>Image is required</small></p>
                  }
                </div>
                <div className='col-6 mt-5'>
                  <Input
                    hidden={categoryId ? true : false}
                    type={'file'}
                    autoComplete='photo'
                    onChange={(e: any) => { setCategoryImage2(e.target.files);setCategoryImage2Name(e.target.files[0].name)}}
                  />
                  {
                    isInvalidForm && CommonService.isUndefinedOrNull([categoryImage2Name]) &&
                    <p className="text-danger ms-2"><small>Image is required</small></p>
                  }
                </div>
              </>
            }
          </div>
          <div>
            <Button
              type="button" 
              color='info'
              className='w-25 mt-5'
              onClick={() => { onSaveHandleClick(); }}
            >
              Save
            </Button>
            <Button
              type="button"
              color='danger'
              className='w-25 mt-5 float-end'
              onClick={(event:any) => { event.preventDefault(); onCancelClick()}}
            >
              Cancel
            </Button>
          </div>
          {
            categoryId &&
            <div className='row text-center'>
                <div className='col-6 mt-5'>
                    <div  className='d-inline-flex'>
                        <img className='cursor-pointer border border-light' src={categoryImage1Name && imgPath + categoryImage1Name || NoImage } alt="img1" height={150} width={150} onClick={() => img1Ref.current.click()} />
                        {
                            categoryImage1Name &&
                            <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"}  onClick={() => onDeleteImageClick("categoryImage1")} />
                        }
                    </div>
                    <Input
                        hidden={!!categoryId}
                        ref={img1Ref}
                        type={'file'}
                        autoComplete='photo'
                        onChange={(e: any) => {setCategoryImage1(e.target.files); onUploadSubCategoryImage(e,"categoryImage1") }}
                    />
                </div>
                <div className='col-6 mt-5'>
                    <div className='d-inline-flex'>
                        <img className='cursor-pointer border border-light' src={categoryImage2Name && imgPath + categoryImage2Name || NoImage } alt="img2"  height={150} width={150} onClick={() => img2Ref.current.click()}   />
                        {
                            categoryImage2Name &&
                            <Icon className='navigation-icon align-self-end ms-3' icon={"Delete"} onClick={() => onDeleteImageClick("categoryImage2")} />
                        }
                    </div>
                    <Input
                        hidden={!!categoryId}
                        type={'file'}
                        ref={img2Ref}
                        autoComplete='photo'
                        onChange={(e: any) => {setCategoryImage2(e.target.files); onUploadSubCategoryImage(e,"categoryImage2")}}
                    />
                </div>
            </div>
        }
        </CardBody>
      </Card>
    </div >
  )
}

export default AddEditCategory;